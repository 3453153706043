import React from "react"
import { Fragment, useState, useEffect, FC } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import {
  useCart,
  useCheckoutUrl,
  useRemoveItemFromCart,
  useUpdateItemQuantity,
} from "@lib/swell/storefront-data-hooks"
import { BuilderComponent, builder } from "@builder.io/react"
import env from "@config/env"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import { OptionValue } from "@lib/swell/storefront-data-hooks/src/types"
import Link from "next/link"
import Image from "next/image"

type CartSidebarViewProps = {
  openSidebar: boolean
  setOpenSidebar: any
}
const CartSidebarView = ({ openSidebar, setOpenSidebar }: CartSidebarViewProps) => {
  const checkoutUrl = useCheckoutUrl()
  const cart = useCart()
  const removeItem = useRemoveItemFromCart()
  const updateItem = useUpdateItemQuantity()
  const [removing, setRemoving] = useState(false)
  const subTotal = getPrice(cart?.sub_total, cart?.currency ?? "USD")
  const total = getPrice(cart?.grand_total, cart?.currency ?? "USD")
  const shippingTotal = getPrice(cart?.shipment_total, cart?.currency ?? "USD")
  const taxTotal = getPrice(cart?.tax_total, cart?.currency ?? "USD")
  const items = cart?.items ?? []
  const isEmpty = items.length === 0
  const [cartUpsell, setCartUpsell] = useState()
  const calculateDiscount = () => {
    return (
      cart?.items?.reduce(
        (accumulator, item) => accumulator + item.product.price - item.price,
        0
      ) || 0
    )
  }
  const sale = calculateDiscount() > 0 ? true : false
  const updateQuantity = async (item: any, quantity: number) => {
    await updateItem(item.id, quantity)
  }

  useEffect(() => {
    async function fetchContent() {
      const items = cart?.items || []
      const cartUpsellContent = await builder
        .get("cart-upsell-sidebar", {
          cachebust: env.isDev,
          userAttributes: {
            itemInCart: items.map((item: any) => item.product?.slug),
          } as any,
        })
        .toPromise()
      setCartUpsell(cartUpsellContent)
    }
    fetchContent()
  }, [cart?.items])

  return (
    <Transition.Root show={openSidebar} as={Fragment}>
      <Dialog as="div" className="relative" onClose={setOpenSidebar}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Shopping cart
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpenSidebar(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul role="list" className="-my-6 divide-y divide-gray-200">
                            {items.map((item: any) => (
                              <li key={item.id} className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                  {item.product.images?.[0] && (
                                    <img
                                      src={item.product.images?.[0]?.file.url}
                                      alt={item.product.images?.[0]?.file.metadata}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  )}
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>
                                        <a href={`/product/${item.product.slug}`}>
                                          {item.product.name}
                                        </a>
                                      </h3>
                                      <div className="flex flex-col">
                                        {item.product.sale && (
                                          <div className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                            -
                                            {getPrice(
                                              item.product.price - item.product.sale_price,
                                              cart?.currency ?? "USD"
                                            )}
                                          </div>
                                        )}
                                        <div>
                                          {getPrice(
                                            item.product.sale
                                              ? item.product.sale_price
                                              : item.product.price,
                                            cart?.currency ?? "USD"
                                          )}
                                        </div>
                                        {item.product.content.deposit && (
                                          <div className="text-sm font-normal">
                                            ({getPrice(item.price, cart?.currency ?? "USD")} due
                                            today)
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {item?.options.map((option: OptionValue) => (
                                      <div key={option.id} className="mt-1 flex text-xs">
                                        <div className="text-gray-500">{option.name}</div>

                                        <div className="ml-4 truncate border-l border-gray-200 pl-4 text-gray-500">
                                          {option.value}
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <p className="text-gray-500">Qty {item.quantity}</p>

                                    <div className="flex">
                                      <button
                                        type="button"
                                        className="text-primary-600 hover:text-primary-500 font-medium"
                                        onClick={(e) => updateQuantity(item, item.quantity - 1)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <p>{subTotal}</p>
                      </div>

                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>
                      <div className="mt-6">
                        {checkoutUrl && (
                          <Link
                            href={checkoutUrl}
                            className="flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700"
                          >
                            Checkout
                          </Link>
                        )}
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or
                          <button
                            type="button"
                            className="text-primary-600 hover:text-primary-500 font-medium"
                            onClick={() => setOpenSidebar(false)}
                          >
                            &nbsp;Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CartSidebarView
