/** @jsxImportSource theme-ui */

import { Card, Text } from "@theme-ui/components"
import { Link, ImageCarousel } from "@components/ui"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import { Product } from "@lib/swell/storefront-data-hooks/src/types"

export interface ProductCardProps {
  className?: string
  product: Product
  imgWidth: number | string
  imgHeight: number | string
  imgLayout?: "fixed" | "intrinsic" | "responsive" | undefined
  imgPriority?: boolean
  imgLoading?: "eager" | "lazy"
  imgSizes?: string
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  imgWidth,
  imgHeight,
  imgPriority,
  imgLoading,
  imgSizes,
  imgLayout = "responsive",
}) => {
  const handle = product.slug
  const price = getPrice(product.price, product.currency ?? "USD")

  return (
    <Card
      sx={{
        maxWidth: [700, imgWidth || 540],
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Link href={`/product/${handle}/`}>
        <div sx={{ flexGrow: 1 }}>
          <ImageCarousel
            currentSlide={product.images ? product.images.length - 1 : 0}
            width={imgWidth}
            height={imgHeight}
            priority={imgPriority}
            loading={imgLoading}
            layout={imgLayout}
            sizes={imgSizes}
            alt={product.name}
            images={
              product.images?.length
                ? product.images
                : [
                    {
                      src: `https://via.placeholder.com/${imgWidth}x${imgHeight}`,
                    },
                  ]
            }
          />
        </div>
        <div sx={{ textAlign: "center" }}>
          <h2 sx={{ mt: 4, mb: 0, fontSize: 14 }}>{product.name}</h2>
          <Text sx={{ fontSize: 12, mb: 2 }}>{price}</Text>
        </div>
      </Link>
    </Card>
  )
}

export default ProductCard
