/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { ThemeProvider, jsx } from "theme-ui"
import dynamic from "next/dynamic"
import { ManagedUIContext, useUI } from "@components/ui/context"
import { Head, Navbar } from "@components/common"
import { useAcceptCookies } from "@lib/hooks/useAcceptCookies"
import { Button } from "theme-ui"
import { Sidebar } from "@components/ui"
import { CartSidebarView } from "@components/cart"
import { CommerceProvider } from "@lib/swell/storefront-data-hooks"
import swellConfig from "@config/swell"
import { builder, BuilderContent, Builder } from "@builder.io/react"
import themesMap from "@config/theme"
import "@builder.io/widgets"
import "react-spring-modal/styles.css"
import seoConfig from "@config/seo.json"
import NoSSR from "./NoSSR"
const FeatureBar = dynamic(() => import("@components/common/FeatureBar"), {
  ssr: false,
})
const defaultSidebarState: { openSidebar: boolean; setOpenSidebar: any } = {
  openSidebar: false,
  setOpenSidebar: null,
}
export const sidebarContext = React.createContext(defaultSidebarState)

interface LayoutProps {
  pageProps: any
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children, pageProps }) => {
  const builderTheme = pageProps.theme
  const isLive = !Builder.isEditing && !Builder.isPreviewing
  const [openSidebar, setOpenSidebar] = React.useState(false)
  const sidebarState = { openSidebar, setOpenSidebar }

  return (
    <CommerceProvider {...swellConfig}>
      <BuilderContent isStatic {...(isLive && { content: builderTheme })} modelName="theme">
        {(data, loading) => {
          if (loading && !builderTheme) {
            return "loading ..."
          }
          const siteSettings = data?.siteSettings
          const colorOverrides = data?.colorOverrides
          const siteSeoInfo = data?.siteInformation
          return (
            <ManagedUIContext key={data?.id} siteSettings={siteSettings}>
              <Head seoInfo={siteSeoInfo || seoConfig} />

              <InnerLayout themeName={data?.theme || "base"} colorOverrides={colorOverrides}>
                {children}
              </InnerLayout>
            </ManagedUIContext>
          )
        }}
      </BuilderContent>
    </CommerceProvider>
  )
}

const InnerLayout = ({
  themeName,
  children,
  colorOverrides,
}: {
  themeName: string
  colorOverrides?: {
    text?: string
    background?: string
    primary?: string
    secondary?: string
    muted?: string
  }
  children: React.ReactNode
}) => {
  const theme = {
    ...themesMap[themeName],
    colors: {
      ...themesMap[themeName].colors,
      ...colorOverrides,
    },
  }
  const { displaySidebar, closeSidebar } = useUI()
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { openSidebar, setOpenSidebar } = React.useContext(sidebarContext)

  return <></>
}

export default Layout
