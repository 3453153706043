/** @jsxImportSource theme-ui */

import React, { useEffect, useState } from "react"
import { Link } from "@components/ui"
import { jsx } from "theme-ui"
import { CenterModal, ModalTitle, ModalCloseTarget } from "react-spring-modal"

interface FeatureBarProps {
  className?: string
  description?: string
  hide?: boolean
  action?: React.ReactNode
  delay?: number
}

const FeatureBar: React.FC<FeatureBarProps> = ({ description, action, hide, delay }) => {
  const [delayPassed, setDelayPassed] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setDelayPassed(true), delay || 6000)
    return () => clearTimeout(timeout)
  })
  const PrivacyPolicyLink = () => {
    return (
      <span>
        {"This site uses cookies to improve your experience. By clicking, you agree to "}
        <Link href="/privacy-policy">
          <a className="font-medium text-gray-700 underline">our Privacy Policy.</a>
        </Link>
      </span>
    )
  }
  return (
    <CenterModal isOpen={delayPassed && !hide}>
      <ModalTitle>
        <PrivacyPolicyLink />
      </ModalTitle>

      <div sx={{ display: "flex", justifyContent: "center", p: [1, 2] }}>{action && action}</div>
    </CenterModal>
  )
}

export default FeatureBar
