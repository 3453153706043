import React, { FC, useState, useEffect, useContext } from "react"
import Link from "next/link"
import { UserNav } from "@components/common"
import env from "@config/env"
import { BuilderComponent, builder } from "@builder.io/react"
import { useCart } from "@lib/swell/storefront-data-hooks"
import { useThemeUI } from "theme-ui"
import { useUI } from "@components/ui/context"
import Image from "next/image"
import Searchbar from "./Searchbar"
import { useRouter } from "next/router"
import { sidebarContext } from "@components/common/Layout"

import { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, XMarkIcon, ShoppingCartIcon } from "@heroicons/react/24/outline"
import { MobileSearch, Search } from "@components/AlgoliaSearch"

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}
const ShoppingCartButton = () => {
  const cart = useCart()
  const { setOpenSidebar } = useContext(sidebarContext)

  return (
    <div className="flex flex-1 items-center justify-end">
      <button
        onClick={(e) => setOpenSidebar(true)}
        className="group -m-2 flex items-center rounded-md p-2 text-gray-300 hover:bg-gray-700 hover:text-white"
      >
        <ShoppingCartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
        <span className="ml-2 text-sm font-medium">{cart?.items?.length}</span>
        <span className="sr-only">items in cart, view bag</span>
      </button>
    </div>
  )
}

const Navbar: FC = () => {
  const [announcement, setAnnouncement] = useState()
  const { theme } = useThemeUI()
  const { navigationLinks, logo } = useUI()
  const cart = useCart()
  const router = useRouter()
  const { setOpenSidebar } = useContext(sidebarContext)

  function active(link: string) {
    if (link === router.asPath) return true
    return false
  }

  useEffect(() => {
    let isSubscribed = true
    async function fetchContent() {
      const items = cart?.items || []
      const anouncementContent = await builder
        .get("announcement-bar", {
          cachebust: env.isDev,
          userAttributes: {
            itemInCart: items.map((item: any) => item.product.slug),
          } as any,
        })
        .toPromise()
      isSubscribed ? setAnnouncement(anouncementContent) : null
    }

    fetchContent()
    // cancel subscription to useEffect
    return () => {
      isSubscribed = false
    }
  }, [cart?.items])

  return (
    <React.Fragment>
      <BuilderComponent content={announcement} data={{ theme }} model="announcement-bar" />
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="flex-none">
                    {logo && logo.image && (
                      <Link href="/">
                        <img className="h-6 w-auto" src={logo.image} alt="New Way Van Life" />
                      </Link>
                    )}
                  </div>
                  <div className="hidden lg:ml-6 lg:block">
                    <div className="flex space-x-4">
                      {navigationLinks?.map((link, index) => (
                        <Link
                          key={index}
                          href={link.link}
                          className={classNames(
                            active(link.link)
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                        >
                          {link.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end space-x-4">
                  <Search />
                  <div className="hidden lg:block">
                    <ShoppingCartButton />
                  </div>
                </div>

                <div className="flex space-x-2 lg:hidden">
                  <div className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Search</span>
                    <MobileSearch />
                  </div>

                  {/* Mobile menu button */}
                  <ShoppingCartButton />
                  {/* <UserNav /> */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                {/* <div className="hidden lg:block lg:ml-4">
                  <UserNav />
                </div> */}
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigationLinks?.map((link, index) => (
                  <Disclosure.Button
                    key={index}
                    as={Link}
                    href={link.link}
                    className={classNames(
                      active(link.link)
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                  >
                    {link.title}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </React.Fragment>
  )
}

export default Navbar
