import swell from "swell-js"
import swellConfig from "@config/swell"
import image from "next/legacy/image"
import {
  ProductResult,
  Product,
  Image,
  ProductOption,
} from "@lib/swell/storefront-data-hooks/src/types"

export interface BuillderConfig {
  apiKey: string
  productsModel: string
  collectionsModel: string
  isDemo?: boolean
}

export interface CollectionProductsQuery {
  handle: string
  limit?: number
  cursor?: string
  apiKey?: string
  page?: string
}

function normalizeProduct(product: ProductResult): Product {
  const variants = product.variants?.results ?? []
  // TODO need to get all variants here not just the first page.
  const images = product.images?.map((image: Image) => ({ ...image, src: image.file.url })) ?? []

  // Helper function to process custom_show_if and add to parent_value_ids
  const processOptions = (option: ProductOption) => {
    if (option.custom_show_if) {
      const customShowIfArray = option.custom_show_if.split(",").map((id) => id.trim())
      const parentValueIds = option.parent_value_ids ?? []
      option.parent_value_ids = [...parentValueIds, ...customShowIfArray]
    }
    return option
  }

  // Process options and their values
  const processedOptions =
    product.options?.map((option) => {
      processOptions(option)
      return option
    }) ?? []

  return { ...product, variants, images, options: processedOptions }
}

function normalizeProducts(productResults: ProductResult[]): Product[] {
  return productResults.map((product) => {
    return normalizeProduct(product)
  })
}

export async function searchProducts(searchString: string, limit = 100, offset = 0) {
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  const products = await swell.products.list({
    search: searchString,
    limit,
  })
  return products?.results ? normalizeProducts(products?.results) : []
}

export async function getAllProducts() {
  // limit = 100,
  // offset = 0
  // TODO: add in these params
  await swell.init(swellConfig.storeId, swellConfig.publicKey)

  const productResults = await swell.get("/products", {
    where: { active: true },
    limit: 100,
  })

  return productResults ? normalizeProducts(productResults?.results) : []
}

export async function getAllConfiguratorProducts() {
  // limit = 100,
  // offset = 0
  // TODO: add in these params
  await swell.init(swellConfig.storeId, swellConfig.publicKey)

  const productResults = await swell.get("/products", {
    category: "configurators",
    limit: 100,
  })
  console.log("productResults", productResults)
  return productResults ? normalizeProducts(productResults?.results) : []
}

export async function getAllProductPaths(limit?: number): Promise<string[]> {
  const products: any[] = await getAllProducts()
  return products?.map((entry: any) => entry.slug) || []
}

export async function getAllConfiguratorProductPaths(limit?: number): Promise<string[]> {
  const products: any[] = await getAllConfiguratorProducts()
  return products?.map((entry: any) => entry.slug) || []
}

export async function getProduct(options: { id?: string; slug?: string; withContent?: boolean }) {
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  if (Boolean(options.id) === Boolean(options.slug)) {
    throw new Error("Either a slug or id is required")
  }

  const result = await swell.products.get(options.id || options.slug, {
    expand: ["variants", "categories"],
  })
  return result ? normalizeProduct(result) : null
}

export async function getSwellProduct(options: {
  id?: string
  slug?: string
  withContent?: boolean
}) {
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  if (Boolean(options.id) === Boolean(options.slug)) {
    throw new Error("Either a slug or id is required")
  }

  const result = await swell.products.get(options.id || options.slug, { expand: ["variants"] })
  return result ? result : null
}

export async function getVariant(product: any, selections: any) {
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  if (Boolean(product.id) === Boolean(selections.slug)) {
    throw new Error("Product and selections are required")
  }
  const selectionsObjectForQuery = selections
    .filter((selection: any) => selection.variant)
    .reduce((a: any, selection: any) => ({ ...a, [selection.name]: selection.value }), {})

  const result = await swell.products.variation(product, selectionsObjectForQuery)
  return result ? result : null
}

// TODO: add in collection functions

export async function getAllCollections(
  config: BuillderConfig,
  limit = 20,
  offset = 0,
  fields?: string
) {
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  const categories = await swell.categories.list({
    // limit
  })
  return categories?.results
}

export async function getAllCollectionPaths(
  config: BuillderConfig,
  limit?: number
): Promise<string[]> {
  const collections: any[] = await getAllCollections(config, limit)
  return collections?.map((entry) => entry.slug) || []
}

export async function getCollection(
  config: BuillderConfig,
  options: {
    id?: string
    handle?: string
    productsQuery?: Omit<CollectionProductsQuery, "handle">
  }
) {
  if (Boolean(options.id) === Boolean(options.handle)) {
    throw new Error("Either a handle or id is required")
  }

  const query = options.id || options.handle
  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  const category = await swell.categories.get(query, {
    ...options.productsQuery,
    expand: ["products"],
  })
  const products = category?.products?.results ? normalizeProducts(category?.products?.results) : []
  // const { page, count } = products;
  // TODO: add pagination logic
  const hasNextPage = null
  const nextPageCursor = null

  return {
    ...category,
    products,
    nextPageCursor,
    hasNextPage,
  }
}

export async function getProductsByCollection(
  config: BuillderConfig,
  options: {
    id?: string
    handle?: string
    productsQuery?: Omit<CollectionProductsQuery, "handle">
  }
) {
  if (Boolean(options.id) === Boolean(options.handle)) {
    throw new Error("Either a handle or id is required")
  }

  await swell.init(swellConfig.storeId, swellConfig.publicKey)
  const productsList = await swell.products.list({
    category: options.id || options.handle,
    ...options.productsQuery,
  })
  const category = await swell.categories.get(options.id || options.handle)
  const products = productsList?.results ? normalizeProducts(productsList?.results) : []
  // const { page, count } = products;
  // TODO: add pagination logic
  const hasNextPage = null
  const nextPageCursor = null

  return {
    ...productsList,
    products,
    category,
    nextPageCursor,
    hasNextPage,
  }
}
