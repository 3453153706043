import React, { useEffect, useState } from "react"

interface NoSSRProps {
  skeleton?: React.ReactNode
  children?: React.ReactNode
}
const NoSSR: React.FC<NoSSRProps> = ({ children, skeleton }) => {
  const [render, setRender] = useState(false)
  useEffect(() => setRender(true), [])
  if (render) {
    return <>{children}</>
  }
  if (skeleton) {
    return <>{skeleton}</>
  }
  return null
}
export default NoSSR
