import { ToastContainer, toast, ToastOptions } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
interface ToastProps {
  type: "error" | "success" | "warning" | "info"
  message: string
}

export const appToast = function ({ type, message }: ToastProps) {
  const baseToastParams: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  }
  switch (type) {
    case "error":
      toast.error(message, baseToastParams)
      break
    case "success":
      toast.success(message, baseToastParams)
      break
    case "warning":
      toast.warning(message, baseToastParams)
      break
    case "info":
      toast.info(message, baseToastParams)
      break
    default:
      toast(message, baseToastParams)
  }
}

const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  )
}
export default Toast
